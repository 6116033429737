<app-header></app-header>
<div class="container center-h center-v">
    <p><small> Acreditación Domicilio > Solicitud > Confirmacion </small> </p><br>
    <mat-card-title>
        <small> Acreditación Domicilio / Confirmacion </small>
    </mat-card-title>
    <br>
    <br>
    <div id="print-section">
        <div class="text">
            <h3 style="color: green;"><strong> Su declaración fue registrada con éxito</strong></h3>
            <br>
            <br>
            <h4>
                <strong>La novedad se aplicará en la base de datos de la Secretaría Distrital de Salud de Bogotá <br>
                    al cierre del próximo día hábil </strong>
            </h4>
        </div>


        <div class="centrofrom center-h center-v">
            <h4 class="text">Usuarios Registrados</h4>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="identificacion">
                    <th mat-header-cell *matHeaderCellDef class="encabesado">Identificación</th>
                    <td mat-cell *matCellDef="let element"> {{element.docNum}}</td>
                </ng-container>
                <ng-container matColumnDef="Usuario">
                    <th mat-header-cell *matHeaderCellDef class="encabesado">Usuario</th>
                    <td mat-cell *matCellDef="let element">{{element.firstName}} {{element.secondName}}
                        {{element.surname}}
                        {{element.secondSurname}}</td>
                </ng-container>
                <ng-container matColumnDef="nacimiento">
                    <th mat-header-cell *matHeaderCellDef class="encabesado">Fecha Nacimiento</th>
                    <td mat-cell *matCellDef="let element">{{element.birthDate | date: 'dd/MM/yyyy'}}</td>
                </ng-container>
                <ng-container matColumnDef="parentesco">
                    <th mat-header-cell *matHeaderCellDef class="encabesado">Parentesco</th>
                    <td mat-cell *matCellDef="let element">{{element.relationship}}</td>
                </ng-container>
                <ng-container matColumnDef="declaracion">
                    <th mat-header-cell *matHeaderCellDef class="encabesado">Fecha declaración</th>
                    <td mat-cell *matCellDef="let element">{{dataSource.statamentsDate | date: 'dd/MM/yyyy'}}</td>
                </ng-container>
                <ng-container matColumnDef="vigencia">
                    <th mat-header-cell *matHeaderCellDef class="encabesado">Fecha vigencia</th>
                    <td mat-cell *matCellDef="let element">{{dataSource.validityDate | date: 'dd/MM/yyyy'}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <br>
        <div class="text">
            <h4>Por favor no olvide actualizar la declaración dentro de cuatro(4) meses.</h4>
        </div>

    </div>

    <br><br><br>
    <p class="text" style="margin-right: 50%;"><small>puede imprimir este soporte</small></p>

    <mat-card-actions>
        <button mat-raised-button color="warn" type="button" class="btn" (click)="atras()" >Atras</button>
        <button mat-raised-button color="primary" type="button" class="btn" (click)="createPDF()">Imprimir</button>
        <button mat-raised-button  type="button" class="btn" routerLink="/Request">Cerrar</button>

    </mat-card-actions>
</div>


<app-footer [title]="title" [fecha]="fecha"></app-footer>
