<header class="header">
    <div class="header-content center-h">
        <div class="titulo">
            <mat-card-title >
               <p style="text-align: left; margin-top: 1rem;">ACREDITADOM </p> 
            </mat-card-title>
            <p class="tltMin">Acreditación de domicilio por decreto 616 de 2022 </p>            

            <p class="tltMax">Acreditación de domicilio para migrantes venezolanos afiliados al régimen subsidiado en Bogotá D.C<br>
                <small>Articulo 2.1.5.4.1 del Decreto 780 del 2016, adicionado por el Decreto 616 de 2022</small>
            </p>
        </div>
        <div class="logotipo">
            <img class="logo" src="assets/img/LogoAlcaldiaBogo.png">
        </div>
    </div>
</header>