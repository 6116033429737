<mat-dialog-content class="mat-typography">
      <p style="color: red; font-size: 20px; ">

        <mat-card class="mat-elevation-z0">
          <mat-card-content>
              <div class="textcenter">
                  <h6 style="text-align: center; font-size: 150%;">
                          Autorizo en forma previa expresa e informada como titular de mis datos
                          a la Secretaría Distrital de Salud y el Fondo Financiero Distrital de Salud,
                          para hacer uso y tratamiento de mis datos personales de conformidad
                          con lo previsto en el Decreto 1377 de 2013 que reglamenta la Ley 1581 de 2012.
                          Los datos personales serán gestionados de forma segura y algunos tratamientos
                          podrán ser realizados de manera directa o a través de encargados,
                          El tratamiento de los datos personales por parte de la Secretaría Distrital de Salud
                          se realizará dando cumplimiento a la Política de Privacidad y
                          Protección de Datos personales que puede ser consultada en:
                          <a class="consulta" href="http://www.saludcapital.gov.co/Documents/Politica_Proteccion_Datos_P.pdf">
                              <div >
                                  Consulta la Politica de Proteccion de Datos
                              </div>
                        </a>
                  </h6>
              </div>
          </mat-card-content>
      </mat-card>
</mat-dialog-content>

<mat-dialog-actions align="end">

  <button   mat-raised-button color="warn" (click)="NoAutorizaPolitica()">No Autorizo </button>

  <button style="left: 60%;" mat-raised-button color="warn" (click)="AutorizaPolitica()">Autorizo </button>
</mat-dialog-actions>






