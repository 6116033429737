export const environment = {
  production: true,
  title: 'Dev',

  baseUrl:"https://wa-aeu-sds-dev-saludmigrantes-back.azurewebsites.net/",

  baseUrlSeguridad:"https://wa-aeu-sds-dev-saludmigrantes-back.azurewebsites.net/api/v1/",


  recaptcha: {
    siteKey: '6LeZH28hAAAAALtUpOptIHiOxeQiIFSE4RgQTJnX',
  },
  fecha: "2022-09-07"
};
